import { useMutation } from "@tanstack/react-query";
import auth, { AuthProviderData } from "../auth";
import User from "../types";
import AppError from "@lib/error";

function useOauth(){
    return useMutation<User, AppError, AuthProviderData>(data => {
        return auth.oauth(data);
    })
}

export default useOauth;

