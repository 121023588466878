import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import {
    Flex,
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    Checkbox,
    useToast,
    Divider,
    Stack,
    Text,
    HStack,
    Box,
    VStack,
} from "@chakra-ui/react";
import { RegisterData } from "@features/users/auth";
import { useRegister } from "@features/users/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import FacebookButton from "../FacebookButton";
import GoogleButton from "../GoogleButton";
import AppleButton from "../AppleButton";
import Link from "next/link";

interface RegisterFormProps {
    onSubmit?: () => void;
}

interface RegisterFormValues extends Omit<RegisterData, "role"> {
    checked: boolean;
}

const schema = Yup.object().shape({
    full_name: Yup.string().required("Name is required."),
    email: Yup.string().required("Email is required.").email("Invalid email."),
    password: Yup.string().required("Password is required."),
    password_confirmation: Yup.string()
        .required("Required.")
        .oneOf([Yup.ref("password")], "Passwords doesn't match"),
    checked: Yup.boolean().isTrue("Required!"),
});

function RegisterForm(props: RegisterFormProps) {
    const {
        formState: { errors },
        handleSubmit,
        register,
        watch
    } = useForm<RegisterFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            email: "",
            full_name: "",
            password: "",
            password_confirmation: "",
            checked: false,
        },
    });

    const isTermsAccepted = watch("checked");

    const toast = useToast();

    const { mutate, isLoading } = useRegister();

    const submit = (values: RegisterFormValues) => {
        mutate(
            { ...values, role: "organisation_admin" },
            {
                onSuccess: () => {
                    props.onSubmit?.();
                },
                onError: (err) => {
                    toast({
                        title: "Failed to register",
                        description: err.message,
                        isClosable: true,
                        status: "error",
                    });
                },
            }
        );
    };

    return (
        <Flex direction="column" as="form" onSubmit={handleSubmit(submit)}>
            <FormControl isInvalid={!!errors.full_name} mb={7}>
                <FormLabel>Name</FormLabel>
                <Input {...register("full_name")} />
                <FormErrorMessage>
                    {errors.full_name && errors.full_name.message}
                </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.email} mb={7}>
                <FormLabel>Email</FormLabel>
                <Input {...register("email")} />
                <FormErrorMessage>
                    {errors.email && errors.email.message}
                </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.password} mb={7}>
                <FormLabel>Password</FormLabel>
                <Input type="password" {...register("password")} />
                <FormErrorMessage>
                    {errors.password && errors.password.message}
                </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.password_confirmation} mb={7}>
                <FormLabel>Confirm Password</FormLabel>
                <Input type="password" {...register("password_confirmation")} />
                <FormErrorMessage>
                    {errors.password_confirmation &&
                        errors.password_confirmation.message}
                </FormErrorMessage>
            </FormControl>

            <FormControl mb={7} isInvalid={!!errors.checked}>
                <Checkbox {...register("checked")}>
                    I have read and agree to the {" "}
                    <Link passHref href="/privacy">
                        <Box as="a" textDecoration="underline" color="blue.600">
                            Terms of Service
                        </Box>
                    </Link>
                </Checkbox>
                <FormErrorMessage>
                    {errors.checked && errors.checked.message}
                </FormErrorMessage>
            </FormControl>

            <Button
                type="submit"
                isLoading={isLoading}
                isDisabled={isLoading || !isTermsAccepted}
                colorScheme="brand"
            >
                Create Your Landing Page
            </Button>

            <Flex my="8" align="center">
                <Divider />
                <Text fontSize="sm" mx="2" color="gray.400">
                    Or
                </Text>
                <Divider />
            </Flex>

            <VStack spacing="4">
                <FacebookButton mode="sign_up" onSubmit={props.onSubmit} />
                <GoogleButton mode="sign_up" onSubmit={props.onSubmit} />
                {/* <AppleButton mode="sign_up" onSubmit={props.onSubmit} /> */}
            </VStack>
        </Flex>
    );
}

export default RegisterForm;
