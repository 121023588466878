import { useMutation } from "@tanstack/react-query";
import AppError from "@lib/error";
import { Organisation } from "../types";
import client from "@lib/client";
import auth from "../auth";

export type OrganisationData = Omit<Organisation, "id" | "industry"> & {
    industry_id: number;
};

const createOrganisation = async (data: OrganisationData): Promise<Organisation> => {
    const res = await client.post("/api/web/organisations", data);

    if(res.status === 422){
        throw new Error("Organisation already exists.");
    }
    
    return res.data.organisation;
}

function useCreateOrganisation(){
    return useMutation(createOrganisation, {
        onSuccess: organisation => {
            auth.attachOrganisation(organisation);
        }
    })
}

export default useCreateOrganisation;