import type { AppProps } from "next/app";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ChakraProvider } from "@chakra-ui/react";
import { AuthProvider } from "@features/users/components";
import AlertProvider from "@features/ui/AlertProvider";
import theme from "@lib/theme";
import SEO from "@features/ui/SEO";
import { useRouter } from "next/router";
import { GoogleOAuthProvider } from "@react-oauth/google";
import auth from "@features/users/auth";
import * as Sentry from "@sentry/nextjs";

auth.addListener(user => {
    if(user) {
        Sentry.setUser({
            id: user.id.toString(),
            email: user.email,
            username: user.full_name,
        });
    }else {
        Sentry.setUser(null);
    }
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            notifyOnChangeProps: "all",
        },
    },
});

function MyApp({ Component, pageProps }: AppProps) {
    const router = useRouter();

    return (
        <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID as string}>
            <ChakraProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <AuthProvider>
                        <AlertProvider>
                            {router &&
                            router.pathname.startsWith("/_sites/") ? null : (
                                <SEO />
                            )}
                            <Component {...pageProps} />
                        </AlertProvider>
                    </AuthProvider>
                </QueryClientProvider>
            </ChakraProvider>
        </GoogleOAuthProvider>
    );
}

export default MyApp;
