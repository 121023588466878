import client from "@lib/client";
import { useQuery } from "@tanstack/react-query";

interface Industry {
    id: number;
    name: string;
}

interface Option {
    value: number | string;
    label: string;
}

export function useIndustriesOptions(){
    return useQuery<Option[]>({
        queryKey: ["industries"],
        queryFn: async () => {
            const res = await client.get<Industry[]>("/api/web/industries");
            return res.data.map(({ id, name }) => ({ value: id, label: name }))
        },
        initialData: []
    })
}

export const employeesCountOptions = ["0-1", "2-10", "11-50", "51-200", "201-500", "501-1000", "1001-5000", "5001-10000", "10000+"].map(count => ({
    label: count,
    value: count
}))

export const findOption = (options: Option[], value: number | string): Option | undefined => {
    return options.find(option => option.value === value)
}