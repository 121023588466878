import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLogin } from "@features/users/hooks";
import { Credentials } from "@features/users/auth";
import {
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Flex,
    Button,
    Divider,
    useToast,
    Text,
    HStack,
    Box,
    VStack
} from "@chakra-ui/react";
import FacebookButton from "../FacebookButton";
import GoogleButton from "../GoogleButton";
import AppleButton from "../AppleButton";
import Link from "next/link";
import { redirect } from "next/dist/server/api-utils";
import { useRouter } from "next/router";

interface LoginFormProps {
    onSubmit?: () => void;
}

const schema = Yup.object().shape({
    email: Yup.string().required("Email is required.").email("Invalid email!"),
    password: Yup.string().required("Password is required."),
});

function LoginForm(props: LoginFormProps) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: { email: "", password: "" },
        resolver: yupResolver(schema),
    });
    const router = useRouter();
    const registerUrl = router.query.redirect ? `/register?redirect=${router.query.redirect}` : "/register"

    const toast = useToast();

    const login = useLogin();

    const submit = (values: Credentials) => {
        login.mutate(
            { credentials: values },
            {
                onError: (err) => {
                    toast({
                        title: "Failed to login!",
                        description: err.message,
                        status: "error",
                        isClosable: true,
                    });
                },
                onSuccess: () => props.onSubmit?.(),
            }
        );
    };

    return (
        <Flex as="form" onSubmit={handleSubmit(submit)} direction="column">
            <FormControl isRequired isInvalid={!!errors.email} mb={7}>
                <FormLabel>Email</FormLabel>

                <Input {...register("email")} />

                <FormErrorMessage>
                    {errors.email && errors.email.message}
                </FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.password} mb={8}>
                <FormLabel>Password</FormLabel>

                <Input type="password" {...register("password")} />

                <FormErrorMessage>
                    {errors.password && errors.password.message}
                </FormErrorMessage>
            </FormControl>

            <Button
                type="submit"
                isDisabled={login.isLoading}
                isLoading={login.isLoading}
                colorScheme="brand"
            >
                Sign In
            </Button>

            <Box my="5" color="gray.500" fontSize="sm">
                Don't have an account? <Link href={registerUrl} passHref><Box as="a" color="blue.600">Get Started For Free</Box></Link>
            </Box>

            <Flex mb="8" align="center">
                <Divider />
                <Text fontSize="sm" mx="2" color="gray.400">Or</Text>
                <Divider />
            </Flex>

            <VStack align="stretch" spacing="4">
                <FacebookButton mode="sign_in" onSubmit={props.onSubmit} />
                <GoogleButton mode="sign_in" onSubmit={props.onSubmit} />
                {/* <AppleButton mode="sign_in" onSubmit={props.onSubmit} /> */}
            </VStack>
        </Flex>
    );
}

export default LoginForm;
