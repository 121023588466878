import React from "react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

interface AlertOptions {
    title: React.ReactNode;
    description?: React.ReactNode;
    action: string;
    onAction: () => void;
}

interface AlertProviderProps {
    children: React.ReactNode;
}

type AlertFn = (options: AlertOptions | null) => void;

const AlertContext = React.createContext<AlertFn>(() => {});

function AlertProvider(props: AlertProviderProps){
    const [alert, setAlert] = React.useState<null | AlertOptions>(null);
    const cancelRef = React.useRef<any>();
    const { mutate, isLoading } = useMutation(async () => alert?.onAction(), {
        onSuccess: () => setAlert(null)
    });

    return (
        <AlertContext.Provider value={setAlert}>
            {props.children}

            <AlertDialog
                isOpen={!!alert}
                onClose={() => setAlert(null)}
                leastDestructiveRef={cancelRef}
            >
                <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold' pb="2">
                        {alert?.title}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {alert?.description}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={() => setAlert(null)}>
                        Cancel
                    </Button>
                    <Button 
                        colorScheme='red' 
                        onClick={() => mutate()}
                        isLoading={isLoading}
                        isDisabled={isLoading} 
                        ml={3}
                    >
                        {alert?.action}
                    </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </AlertContext.Provider>
    )
}

export function useAlert(){
    const context = React.useContext(AlertContext);

    React.useEffect(
        () => {
            return () => {
                context(null)
            }
        },
        []
    )

    if(!context){
        throw new Error("useAlert must be used inside AlertProvider");
    }

    return context;
}

export default AlertProvider;