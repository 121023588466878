import User, { Organisation } from "./types";
import client from "@lib/client"
import AppError from "@lib/error";

type Listener = (user: User | null) => void;

export interface Credentials {
    email: string;
    password: string;
}

export interface RegisterData {
    email: string;
    password: string;
    password_confirmation: string;
    full_name: string;
    role: "organisation_admin" | "engineer"
}

interface LoginResult {
    user: User
}

type RegisterResult = LoginResult;

declare let Reflio: any;

export interface AuthProviderData extends Omit<RegisterData, "password_confirmation" | "password"> {
    provider: "facebook" | "google" | "apple",
    uid: string;
    token: string;
    expires_at: number;
}

class Auth{
    private STORAGE_KEY = "__auth_🗝️__";
    private listeners: Set<Listener>;

    constructor(){
        this.listeners = new Set();
    }

    async register(data: RegisterData): Promise<User>{
        const res = await client.post<RegisterResult>(`/api/web/users`, data);
        if(res.status === 422){
            throw new AppError("User already exists.", "invalid_register_data")
        }

        if(typeof window !== "undefined" && Reflio) {
            await Reflio.signup(data.email)
        }

        const user = res.data.user;
        this.storeUser(user);
        this.listeners.forEach(listener => listener(user));
        return user;
    }

    async login(credentials: Credentials): Promise<User>{
        const res = await client.post<LoginResult>("/api/web/users/login", credentials);
        if(res.status === 422){
            throw new AppError("Invalid email or password", "invalid_credentials");
        }
        const user = res.data.user;
        this.storeUser(user);
        this.listeners.forEach(listener => listener(user));
        return user;
    }

    async oauth(data: AuthProviderData): Promise<User>{
        const res = await client.post<LoginResult>("/api/web/oauth", data);
        const user = res.data.user;
        this.storeUser(user);
        this.listeners.forEach(listener => listener(user));
        return user;
    }

    async logout(){
        this.removeUser();
        this.listeners.forEach(listener => listener(null));
    }

    private storeUser(user: User): void{
        if(typeof window === "undefined") return;
        window.localStorage.setItem(
            this.STORAGE_KEY,
            JSON.stringify(user)
        );
    }

    getUser(): User | null{
        if(typeof window === "undefined") return null;
        const user = window.localStorage.getItem(this.STORAGE_KEY) || JSON.stringify(null);
        return JSON.parse(user);
    }

    private removeUser(): void{
        if(typeof window === "undefined") return;
        window.localStorage.removeItem(this.STORAGE_KEY);
    }

    addListener(listener: Listener): () => void {
        this.listeners.add(listener);

        return () => {
            this.listeners.delete(listener);
        }
    }

    attachOrganisation(organisation: Organisation){
        const user = this.getUser();

        if(!user) return;

        const newUser: User = { 
            ...user, 
            organisation_id: organisation.id,
            organisation: organisation
        };

        this.storeUser(newUser);
        this.listeners.forEach(listener => listener(newUser));
    }

    async refresh(): Promise<void> {
        const res = await client.get("/api/web/users/me");
        const user = res.data.user as User;
        this.storeUser(user);
        this.listeners.forEach(listener => listener(user));
    }
}

const auth = new Auth();

export default auth;