import { useMutation } from "@tanstack/react-query";
import auth, { Credentials } from "@features/users/auth";
import User from "../types";
import AppError from "@lib/error";

interface MutationData {
    credentials: Credentials;
}

function useLogin(){
    return useMutation<User, AppError, MutationData>((data) => {
        return auth.login(data.credentials);
    });
}

export default useLogin;
