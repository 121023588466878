import React from "react";
import { useOauth } from "@features/users/hooks";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Box, Button } from "@chakra-ui/react";
import { ReactFacebookLoginInfo } from "react-facebook-login";
import { FaFacebookF as FacebookIcon } from "react-icons/fa";

interface FacebookButtonProps {
    mode: "sign_in" | "sign_up";
    onSubmit?: () => void;
}

function FacebookButton(props: FacebookButtonProps) {
    const register = useOauth();

    const handleClick = (
        data: ReactFacebookLoginInfo & { data_access_expiration_time: number }
    ) => {
        console.log(data);
        if (!data.userID || !data.accessToken) {
            console.log("Invalid Operation");
            return;
        }

        register.mutate(
            {
                email: data.email || "",
                full_name: data.name || "",
                role: "organisation_admin",
                token: data.accessToken,
                expires_at: data.data_access_expiration_time,
                uid: data.userID,
                provider: "facebook",
            },
            {
                onSuccess: () => props.onSubmit?.(),
            }
        );
    };

    return (
        <FacebookLogin
            appId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID || ""}
            callback={handleClick}
            fields="name,email"
            render={(btnProps) => (
                <Button
                    variant="unstyled"
                    border="1px"
                    borderColor="inherit"
                    width="100%"
                    textAlign="center"
                    display="inline-flex"
                    alignItems="center"
                    justifyContent="center"
                    color="white"
                    rounded="md"
                    boxShadow="sm"
                    bg="blue"
                    _hover={{
                        boxShadow: "lg",
                    }}
                    {...btnProps}
                >
                    <Box as="span" fontSize="xl" mr="4">
                        <FacebookIcon />
                    </Box> {" "}
                    <Box>
                        {props.mode == "sign_in"
                            ? "Sign in with facebook"
                            : "Sign up with facebook"}
                    </Box>
                </Button>
            )}
        />
    );
}

export default FacebookButton;
