import {
    ComponentStyleConfig,
    extendTheme,
    theme as baseTheme,
} from "@chakra-ui/react";

const Button: ComponentStyleConfig = {
    variants: {
        outline: {
            border: "2px",
        },
        glowy: {
            color: "brand.500",
            bg: "white",
            border: "1px",

            _hover: {
                boxShadow:
                    "5px 5px 15px rgb(255, 255, 255, 0.3), -5px -5px 15px rgb(255, 255, 255, 0.3)",
            },
        },
    },
};

const Card: ComponentStyleConfig = {
    baseStyle: {
        rounded: "md",
        p: {
            base: 4,
            md: 6,
        },
    },
    variants: {
        outline: {
            border: "1px",
            borderColor: "inherit",
        },
        solid: {
            bg: "white",
            boxShadow: "base",
        },
    },
    defaultProps: {
        variant: "outline",
    },
};

const Spinner: ComponentStyleConfig = {
    baseStyle: {
        color: "brand.500",
        thickness: "2px",
    },
    defaultProps: {
        size: "md",
    },
};

const theme = extendTheme({
    shadows: {
        outline: "0 0 0 2px var(--chakra-colors-brand-300)"
    },
    styles: {
        global: {
            html: {
                scrollBehavior: "smooth",
            },
        },
    },
    colors: {
        dark: "#0A0021",
        brand: {
            "50": "#f5f3ff",
            "100": "#ede9fe",
            "200": "#ddd6fe",
            "300": "#c4b5fd",
            "400": "#a78bfa",
            "500": "#8b5cf6",
            "600": "#7c3aed",
            "700": "#6d28d9",
            "800": "#5b21b6",
            "900": "#4c1d95",
        },
    },
    components: {
        Card,
        Spinner,
        Button,
        Input: {
            defaultProps: {
                focusBorderColor: "brand.500"
            }
        },
        Textarea: {
            defaultProps: {
                focusBorderColor: "brand.500"
            }
        },
        NumberInput: {
            defaultProps: {
                focusBorderColor: "brand.500"
            }
        }
    },
});

export default theme;
