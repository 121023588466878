import Head from "next/head";
import { useRouter } from "next/router";

const seo = {
    title: "BITHUB - Simplest Way To Build A Landing Page",
    thumbnail: "https://the-bithub.com/images/thumbnail.png",
    description:
        "Create a simple, beautiful and flexible Landing Page that brings together all of your best content in one place and makes visitors want to join your audience, subscribe to your service, or buy your stuff. #NoCodeRequired",
};

function SEO() {
    const router = useRouter();

    return (
        <Head>
            <title>BITHUB - Simplest Way To Build A Landing Page</title>

            <meta name="description" content={seo.description} />

            <meta property="og:type" content="website" />
            <meta property="og:title" content={seo.title} />
            <meta property="og:description" content={seo.description} />
            <meta property="og:image" content={seo.thumbnail} />
            <meta property="og:url" content={`https://the-bithub.com${router.asPath}`} />
            <meta property="og:site_name" content="Bithub" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@the_bithub" />
            <meta name="twitter:title" content={seo.title} />
            <meta
                name="twitter:description"
                content={seo.description}
            />
            <meta
                name="twitter:image"
                content={seo.thumbnail}
            />

            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/images/apple-touch-icon.png"
                type="image/png"
            />
            <link
                rel="shortcut icon"
                href="/images/light-mode-favicon.ico"
                media="(prefers-color-scheme: light)"
            />
            <link
                rel="shortcut icon"
                href="/images/dark-mode-favicon.ico"
                media="(prefers-color-scheme: dark)"
            />
            <link rel="manifest" href="/site.webmanifest" />
        </Head>
    );
}

export default SEO;
