import { useOauth } from "@features/users/hooks";
import { Box, Button } from "@chakra-ui/react";
import { FcGoogle as GoogleIcon } from "react-icons/fc";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

interface GoogleButtonProps {
    mode: "sign_in" | "sign_up";
    onSubmit?: () => void;
}

function GoogleButton(props: GoogleButtonProps) {
    const register = useOauth();

    const login = useGoogleLogin({
        onSuccess: (res) => {
            console.log({ res });
            if (!res.access_token) {
                console.log("Invalid Operation");
                return;
            }
            axios
                .get(
                    `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${res.access_token}`,
                    {
                        headers: {
                            Authorization: `Bearer ${res.access_token}`,
                            Accept: "application/json",
                        },
                    }
                )
                .then(({ data }) => {
                    register.mutate(
                        {
                            email: data.email || "",
                            full_name: data.name || "",
                            role: "organisation_admin",
                            token: res.access_token,
                            expires_at: res.expires_in,
                            uid: data.id,
                            provider: "google",
                        },
                        {
                            onSuccess: () => props.onSubmit?.(),
                        }
                    );
                });
        },
    });

    return (
        <Button
            onClick={() => login()}
            variant="unstyled"
            border="1px"
            borderColor="inherit"
            width="100%"
            textAlign="center"
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            rounded="md"
            boxShadow="sm"
            _hover={{
                boxShadow: "lg",
            }}
        >
            <Box as="span" fontSize="xl" mr="4">
                <GoogleIcon />
            </Box>{" "}
            <Box>
                {props.mode == "sign_in"
                    ? "Sign in with google"
                    : "Sign up with google"}
            </Box>
        </Button>
    );
}
export default GoogleButton;
