import React from "react";
import auth from "@features/users/auth";
import User from "@features/users/types";
import { v4 } from "uuid";
import { useQueryClient } from "@tanstack/react-query";

interface AuthContextValue {
    user: null | User | undefined;
    sessionId: string;
}

interface AuthProviderProps {
    children: React.ReactNode;
}

export const AuthContext = React.createContext<AuthContextValue>({ user: undefined, sessionId: "" });

function AuthProvider(props: AuthProviderProps){
    const [user, setUser] = React.useState<User | null | undefined>(undefined);
    const [sessionId, setSessionId] = React.useState<string>("");
    const queryClient = useQueryClient();

    React.useEffect(
        () => {
            const removeListener = auth.addListener(user => {
                setUser(user);
                if(!user){
                    queryClient.clear();
                }
            });

            return () => {
                removeListener();
            }
        },
        []
    );

    React.useEffect(
        () => {
            const previousSessionId = window.localStorage.getItem("session_id");

            if(!previousSessionId){
                setSessionId(v4())
            }else{
                setSessionId(previousSessionId);
            }
        },
        []
    )

    React.useEffect(
        () => {
            const user = auth.getUser();
            setUser(user);
            if(user){
                auth.refresh();
            }
        },
        []
    )

    const value = React.useMemo(
        () => ({
            user,
            sessionId
        }),
        [user]
    )

    return (
        <AuthContext.Provider value={value}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;