import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { 
    Flex,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    Button,
    useToast
} from "@chakra-ui/react";
import Select from "@features/ui/Select";
import { useCreateOrganisation } from "@features/users/hooks";
import { useIndustriesOptions, employeesCountOptions, findOption } from "./OrganisationForm.helpers";
import { OrganisationData } from "@features/users/hooks/useCreateOrganisation";

interface OrganisationFormProps {
    onSubmit?: () => void;
}

const schema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    employees_number: Yup.string().required("Employees count is required."),
    industry_id: Yup.number().required("Industry is required.")
});

function OrganisationForm(props: OrganisationFormProps){
    const {
        register,
        formState: { errors },
        handleSubmit,
        control
    } = useForm<OrganisationData>({
        resolver: yupResolver(schema)
    });

    const { data: industriesOptions } = useIndustriesOptions();
    const toast = useToast();
    const { mutate, isLoading } = useCreateOrganisation();

    const onSubmit = (values: OrganisationData) => {
        mutate(values, {
            onSuccess: () => {
                props.onSubmit?.()
            },
            onError: (err: any) => {
                toast({
                    title: "Failed to create organisation",
                    description: err.message,
                    isClosable: true,
                    status: "error"
                })
            }
        })
    }

    return (
        <Flex
            direction="column"
            as="form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <FormControl
                isInvalid={!!errors.name}
                mb={7}
            >
                <FormLabel>Organisation Name</FormLabel>
                <Input {...register("name")} />
                <FormErrorMessage>
                    {errors.name && errors.name.message}
                </FormErrorMessage>
            </FormControl>

            <FormControl
                isInvalid={!!errors.industry_id}
                mb={7}            
            >
                <FormLabel>Industry</FormLabel>
                <Controller 
                    control={control}
                    name="industry_id"
                    render={({ field }) => (
                        <Select
                            onChange={({ value }: any) => field.onChange(value)}
                            onBlur={field.onBlur}
                            value={findOption(industriesOptions, field.value)}
                            options={industriesOptions}
                        />
                    )}
                />
                <FormErrorMessage>
                    {errors.industry_id && errors.industry_id.message}
                </FormErrorMessage>
            </FormControl>

            <FormControl
                isInvalid={!!errors.employees_number}
                mb={7}            
            >
                <FormLabel>Employees Count</FormLabel>
                <Controller 
                     control={control}
                     name="employees_number"
                     render={({ field }) => (
                         <Select
                             onChange={({ value }: any) => field.onChange(value)}
                             onBlur={field.onBlur}
                             value={findOption(employeesCountOptions, field.value)}
                             options={employeesCountOptions}
                             ref={field.ref}
                        />
                    )}
                /> 
                <FormErrorMessage>
                    {errors.employees_number && errors.employees_number.message}
                </FormErrorMessage>
            </FormControl>

            <Button
                type="submit"
                isLoading={isLoading}
                disabled={isLoading}
                colorScheme="brand"
            >
                Submit
            </Button>
        </Flex>
    )
}

export default OrganisationForm;