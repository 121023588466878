import AppError from "@lib/error";
import { useMutation } from "@tanstack/react-query";
import auth, { RegisterData } from "../auth";
import User from "../types";

function useRegister(){
    return useMutation<User, AppError, RegisterData>(data => {
        return auth.register(data);
    });
}

export default useRegister;